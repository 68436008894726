<template>
    <GMapMap :center="{ lat: coords.lat, lng: coords.lng }" :zoom="15" ref="myMapRef" map-type-id="roadmap"
        :style="{height: height}" class="mt-2 mb-2 w-100" :options="state.options">
        <GMapMarker :position="coords" :clickable="true" :draggable="!isDisabled" ref="markerRef"
            @dragend="onmarkerDragged" :icon=markerIcon>
        </GMapMarker>
    </GMapMap>
</template>

<script setup>
import { reactive, onMounted, ref, watch} from "vue";

const emit = defineEmits(['setLocation']);
const props = defineProps({
  height: {
    type: String,
    default: '400px'
  },
  coords: {
    type: Object,
    default: () => ({ lat: 24.710988, lng: 46.664734 })
  },
  locateMe: {
    type: Boolean,
    default: true
  },
  isDisabled: {
    type: Boolean,
    default: false
  }
});
const state = reactive({
    loading: false,
    error: null,
    options: {
        styles: [
            {
                featureType: "poi",
                elementType: "labels",
                stylers: [{ visibility: "off" }],
            },
            {
                featureType: "road",
                elementType: "geometry",
                stylers: [{ "color": "#dddbe8" }]
            },
            {
                featureType: "landscape",
                elementType: "geometry",
                stylers: [{ "color": "#f8f8fa" }]
            }
        ]
    },
});

const myMapRef = ref();
const markerRef = ref();


const user_coords = reactive({ lat: props.coords.lat, lng:props.coords.lng }); //default location in case user doesn't allow location access
const coords = reactive({  lat: props.coords.lat, lng:props.coords.lng });

const staticUrl = process.env.VUE_APP_STATIC_URL;

const markerIcon = {
    //url: require('@/assets/icons/icon-user-map.png'),
    url: `${staticUrl}/static%2Fimages%2Ficon-user-map.png`,
    scaledSize: { width: 25, height: 32 },
};


const addMyButton = (map) => {
    const controlUI = document.createElement("a");
    controlUI.style.border = "none";
    controlUI.style.background = "transparent";
    controlUI.style.paddingLeft = "7px";
    controlUI.style.paddingBottom = "5px";
    controlUI.style.cursor = "pointer";


    const controlIcon = document.createElement("img");
    //controlIcon.src = require('@/assets/icons/icon-map-locate.png'); 
    controlIcon.src = `${staticUrl}/static%2Fimages%2Ficon-map-locate.png`;
    controlIcon.style.width = "25px";
    controlIcon.style.height = "23px";

    controlUI.appendChild(controlIcon);
    controlUI.addEventListener("click", () => {
        map.setZoom(15);
        map.setCenter({ lat: user_coords.lat, lng: user_coords.lng });
        resetCoords();
        markerRef.value.$markerObject.setPosition(coords);
    });

    map.controls[google.maps.ControlPosition.LEFT_BOTTOM].push(controlUI);

}

const resetCoords = () => {
    coords.lat = user_coords.lat;
    coords.lng = user_coords.lng;
};

const useGeoLocation = () => {
    const isSupported = 'navigator' in window && "geolocation" in navigator; //to check if geolocation api is supported before accessing it
    if (isSupported) {
        navigator.geolocation.getCurrentPosition((position) => {
            user_coords.lat = position.coords.latitude;
            user_coords.lng = position.coords.longitude;
            resetCoords();
        });
    }
};


const onmarkerDragged = () => {
    const position = markerRef.value.$markerObject.getPosition();
    coords.lat = position.lat();
    coords.lng = position.lng();
};


const initializeMap = () => {
    if (myMapRef.value) {
        myMapRef.value.$mapPromise.then((map) => {
            addMyButton(map);
        }).catch((error) => {
            console.error(error);
        });
    }
}

watch(coords, (newCoords) => {
  emit('setLocation', newCoords);
});

onMounted(() => {
    initializeMap()
    if(props.locateMe){
        useGeoLocation();}

})

</script>



<style lang="scss" scoped>
@import "@/styles/variables";
</style>