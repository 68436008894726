<template>
  <div>
    <div class="welcome-container row">
      <div class="col-12 title">بيانات مدير الجمعية:</div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="information-container mt-2 mb-2">
          <img
            style="margin-right: 1rem"
            :src="`${staticUrl}/static/images/info.png`"
            alt="info"
            height="20px"
            width="20px"
          />
          <div style="margin-right: 0.5rem">
            يلزم أن يكون المسجل مدير الجمعية.
          </div>
        </div>
      </div>
    </div>

    <form class="form-step1 w-100">
      <!-- ID NUMBER -->
      <div class="form-label-group mb-3">
        <div class="mt-4">
          <span class="p-float-label">
            <InputText
              id="national_id"
              type="number"
              v-model="payload.national_id"
              :class="[
                'w-100',
                {
                  'p-invalid':
                    (submitted && (!payload.national_id || !verified_national_id)) || errors.national_id
                      ? true
                      : false,
                },
              ]"
              :disabled="loading"
            />
            <div
            v-if="errors.national_id"
            class="error-message">
            {{ errors.national_id }}
          </div>
            <label for="national_id">رقم الهوية</label>
          </span>
        </div>
      </div>
<!-- EMAIL -->
      <div class="form-label-group mb-3">
        <div class="mt-4">
          <span class="p-float-label">
            <InputText
              id="email"
              type="email"
              v-model="payload.email"
              :class="[
                'w-100',
                {
                  'p-invalid':
                    (submitted && !payload.email) ||
                    (payload.email && !verified_email) || errors.email
                      ? true
                      : false,
                },
              ]"
              :disabled="loading"
            />
            <div
            v-if="errors.email"
            class="error-message">
            {{ errors.email }}
          </div>
            <label for="email">البريد الإلكتروني</label>
          </span>
          <div
            v-if="payload.email && !verified_email"
            class="error-message"
          >
            الرجاء إدخال عنوان بريد إلكتروني صالح
          </div>
        </div>
      </div>
<!-- Phone number -->
      <div class="form-label-group">
        <div class="mt-4">
          <span class="p-float-label">
            <BaseInputNumber
              :label="'رقم الجوال'"
              v-model="payload.mobile_number" 
              :disabled="loading"
              :class="{'p-invalid': !!((submitted && !payload.mobile_number) ||
                    (payload.mobile_number && !verify_mobile(payload.mobile_number)) || errors.mobile_number)}"
              id="mobile_number"
              :maxLength="10"
              :phoneNumber="true"
            />
          </span>
          <div
            v-if="(payload.mobile_number && !verify_mobile(payload.mobile_number))"
            class="error-message"
          >
            الرجاء إدخال رقم جوال صحيح
          </div>
          <div
            v-if="errors.mobile_number"
            class="error-message">
            {{ errors.mobile_number }}
          </div>
        </div>
      </div>
<!-- Birthday -->
      <div class="row mt-2">
        <div class="form-label-group col-lg-6">
          <div class="mt-4">
            <span class="p-float-label">
              <BaseCalendar
                calendar-id="gregorian-birthdate"
                type="gregorian"
                placeholder="تاريخ الميلاد (ميلادي)"
                v-model="payload.gregorian_birthdate"
                :class="[
                  'w-100',
                  {
                    'p-invalid':
                      (submitted && !payload.gregorian_birthdate) || errors.gregorian_birthdate  ,
                  },
                ]"
                :disabled="loading"
                :maxDate="currentdDate"
                @dateChange="payload.hijri_birthdate = $event"
              />
              <div
                v-if="errors.gregorian_birthdate"
                class="error-message">
                {{ errors.gregorian_birthdate}}
          </div>
            </span>
          </div>
        </div>

        <div class="form-label-group col-lg-6">
          <div class="mt-4">
            <span class="p-float-label">
              <BaseCalendar
                calendar-id="hijri-birthdate"
                type="hijri"
                placeholder="تاريخ الميلاد (هجري)"
                v-model="payload.hijri_birthdate"
                :class="[
                  'w-100',
                  {
                    'p-invalid':
                      (submitted && !payload.hijri_birthdate) || errors.hijri_birthdate
                  },
                ]"
                :disabled="loading"
                :maxDate="currentdDate"
                @dateChange="payload.gregorian_birthdate = $event"
              />
              <div
                v-if="errors.hijri_birthdate"
                class="error-message">
                {{ errors.hijri_birthdate}}
          </div>
            </span>
          </div>
        </div>
      </div>

      <div class="accept-checkbox mt-3">
        <Checkbox
          v-model="payload.the_charity_manager_is_the_chairman"
          :binary="true"
          inputId="chkbox1"
          :disabled="loading"
        />
        <label for="chkbox1"> مدير الجمعية هو رئيس مجلس إدارتها؟ </label>
      </div>
      <div v-if="!payload.the_charity_manager_is_the_chairman">
        <div class="form-label-group mb-3">
          <div class="mt-4">
          <span class="p-float-label">
            <InputText
              id="chairman_name"
              type="text"
              v-model="payload.chairman_name"
              :class="[
                'w-100',
                {
                  'p-invalid':
                    (submitted && !payload.chairman_name) || errors.chairman_name,
                },
              ]"
            />
            <label for="chairman_name">اسم رئيس مجلس الإدارة</label>
            <div
                v-if="errors.chairman_name"
                class="error-message">
                {{ errors.chairman_name }}
          </div>
          </span>
          </div>
        </div>
        <div class="form-label-group">
          <div class="mt-4">
          <span class="p-float-label">
            <BaseInputNumber
              :label="'رقم جوال رئيس مجلس الإدارة'"
              v-model="payload.chairman_mobile" 
              :disabled="loading || payload.is_chairman_mobile_verified ||payload.the_charity_manager_is_the_chairman"
              :class="{ 'p-invalid': (submitted && !payload.chairman_mobile) ||
                        (payload.chairman_mobile && !verify_mobile(payload.chairman_mobile)) || errors.chairman_mobile
                      }"
              id="chairman_mobile"
              :maxLength="10"
              :phoneNumber="true"
            />
          </span>
            <div
              v-if="payload.chairman_mobile && !verify_mobile(payload.chairman_mobile)"
              class="error-message"
              style="color: #e24c4c"
            >
              الرجاء إدخال رقم جوال صحيح
            </div>
            <div
                v-if="errors.chairman_mobile"
                class="error-message">
                {{ errors.chairman_mobile }}
          </div>
          </div>
        </div>
      </div>
    </form>

    <div class="border-container"></div>

    <div class="row">
      <div class="col-lg-6">
        <button
          id="close-btn"
          class="btn btn-lg btn-secondary btn-block close-btn"
          type="button"
          :disabled="loading"
          @click.stop="$emit('close')"
        >
          إغلاق
        </button>
      </div>
      <div class="col-lg-6">
        <button
          id="tracking-btn"
          class="btn btn-lg btn-primary btn-block tracking-btn"
          type="button"
          :disabled="loading"
          @click.stop="submit"
        >
          <span v-if="loading" class="spinner-border spinner-border-sm mx-1"></span>
          متابعة
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Checkbox from "primevue/checkbox";
import CharityRegisterService from "../../../services/charity-register.service";
import BaseCalendar from "@/components/layout/BaseCalendar";
import {setLocalValue} from '@/hooks/useLocalStorage';
import {useVuelidate} from "@vuelidate/core";
import { scrollNextErrorIntoView } from "@/helpers/helpers";
import { nextTick } from "vue";
export default {
  name: "create-account-step1",
  inject: ['staticUrl'],
  props: ["payload", "verified_info"],
  emits: ["close", "next"],

  components: { Checkbox, BaseCalendar },
  setup() {
    const v$ = useVuelidate();
    return { v$ };
  },
  data() {
    return {
      loading: false,
      errors: {
        national_id: null,
        email: null,
        mobile_number: null,
        hijri_birthdate: null,
        gregorian_birthdate: null,
        the_charity_manager_is_the_chairman: null,
        chairman_name: null,
        chairman_mobile: null,
      },
      submitted: false,
      initialData: null,
      currentdDate: new Date(),
    };
  },
  created() {
    this.payload.gregorian_birthdate = 
      this.payload.gregorian_birthdate 
      && new Date(this.payload.gregorian_birthdate) 
      || '';
  },
  methods: {
    submit() {
      this.submitted = true;
      if (
        this.payload.national_id &&
        this.verified_national_id &&
        this.payload.email &&
        this.verified_email &&
        this.payload.mobile_number &&
        this.verify_mobile(this.payload.mobile_number) &&
        this.payload.gregorian_birthdate &&
        this.payload.hijri_birthdate &&
        Object.values(this.errors).every(value => value === null)
      ) {
        if (!this.payload.the_charity_manager_is_the_chairman && (!this.payload.chairman_name || !this.verify_mobile(this.payload.chairman_mobile))) {
          return;
        }
        // check If data is changed or not
        this.initialData !== JSON.stringify(this.payload) ? this.sendToApi() : this.$emit('next');
      }
      else {
        nextTick(() => scrollNextErrorIntoView())
      }
    },
    verify_mobile(number) {
      const pattern =
        /^(009665|9665|\\+9665|05|5)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/;
      const result = pattern.test(number);

      return result;
    },
    sendToApi() {
      const payload = {
        national_id: this.payload.national_id,
        hijri_birthdate: this.payload.hijri_birthdate,
        gregorian_birthdate: this.payload.gregorian_birthdate,
        mobile_number: this.payload.mobile_number,
        email: this.payload.email,
        the_charity_manager_is_the_chairman:
          this.payload.the_charity_manager_is_the_chairman,
        chairman_name: this.payload.chairman_name,
        chairman_mobile: this.payload.chairman_mobile,
      };

      if (this.payload.the_charity_manager_is_the_chairman) {
        this.payload.chairman_name = null;
        this.payload.chairman_mobile = null;
        delete payload.chairman_name;
        delete payload.chairman_mobile;
      }

      this.loading = true;
      this.error = null;

      CharityRegisterService.step1(payload) //Yakeen API is called here
        .then(({ data }) => {
          //following code was originally in step 1 verification but now in first step
            const {
              first_name,
              father_name,
              last_name,
              identity_card_expiry,
              gender,
            } = data.result;
            this.setVerifiedInfo(
              first_name,
              last_name,
              father_name,
              gender,
              identity_card_expiry
            );
          const charityRequest = { step1: payload}
          setLocalValue('charity_request', charityRequest);
          setLocalValue('verified_info',this.$props.verified_info);
          this.$emit('next');
        })
        .catch((error) => {
          for (const key in error.response.data.field_errors) {
            this.errors[key] = error.response.data.field_errors[key];
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    setVerifiedInfo(
      first_name,
      last_name,
      father_name,
      gender,
      identity_card_expiry
    ) {
      this.$props.verified_info.first_name = first_name;
      this.$props.verified_info.last_name = last_name;
      this.$props.verified_info.father_name = father_name;
      this.$props.verified_info.gender = gender;
      this.$props.verified_info.identity_card_expiry = identity_card_expiry;
    },
  },
  computed: {
    verified_mobile() {
      const pattern =
        /^(009665|9665|\\+9665|05|5)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/;
      const result = pattern.test(this.payload.mobile_number);

      return result;
    },
    verified_email() {
      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const result = pattern.test(this.payload.email);

      return result;
    },
    verified_national_id() {
      return (
        this.payload.national_id &&
        this.payload.national_id.length &&
        this.payload.national_id.length == 10
      );
    },
  },
  watch: {
  'payload.national_id': function() {
    this.errors.national_id = null;
    this.payload.number_of_retry = 0;
  },
  'payload.hijri_birthdate': function() {
    this.errors.hijri_birthdate = null;
  },
  'payload.gregorian_birthdate': function() {
    this.errors.gregorian_birthdate = null;
  },
  'payload.mobile_number': function() {
    this.errors.mobile_number = null;
  },
  'payload.email': function() {
    this.errors.email = null;
  },
  'payload.the_charity_manager_is_the_chairman': function() {
    this.errors.the_charity_manager_is_the_chairman = null;
  },
  'payload.chairman_name': function() {
    this.errors.chairman_name = null;
  },
  'payload.chairman_mobile': function() {
    this.errors.chairman_mobile = null;
  },
},  
mounted() {
    this.initialData = JSON.stringify(this.payload);
}
};
</script>

<style lang="scss" scoped>
.welcome-container {
  .title {
    font-size: 24px;
    font-weight: bold;
  }
  .sub-title {
    font-size: 18px;
  }
}
.information-container {
  color: #696cff;
  background-color: #e9e9ff;
  border-radius: 5px;
  height: 60px;
  font-size: 16px;
  border-right: 5px solid #696cff;
  display: flex;
  align-items: center;
}
.accept-checkbox {
  label {
    font-size: 14px;
    color: #3f3f3f;
    margin-right: 5px;
  }
}
.border-container {
  border-top: 1px solid #dadada;
  padding-top: 2rem;
  margin-top: 2rem;
}

.tracking-btn {
  display: block;
  margin: 0 auto;
  width: 80%;
  border-radius: 25px;
  padding: 10px;
  background: #12b2aa;
  color: #ffffff;
  border: none;
  font-size: 18px;
}

.tracking-btn:hover {
  background-color: #0e8e88 !important;
  border: none;
}

.close-btn {
  display: block;
  margin: 0 auto;
  width: 80%;
  border-radius: 25px;
  padding: 10px;
  background: #d9d9d9;
  color: #3f3f3f;
  border: none;
  font-size: 18px;
}
</style>

<style>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
