<template>
  <div>
    <div class="welcome-container row">
      <div class="col-12 title">التحقق من هوية مدير الجمعية</div>
      <div class="col-12 sub-title">
        يرجى إدخال رمز التحقق المرسل على كل من رقم الجوال والبريد الإلكتروني:
      </div>
    </div>

    <form class="form-step1 w-100">
      <div class="form-label-group mb-3" style="position: relative">
        <div class="mt-4">
          <span class="p-float-label">
            <InputText
              id="mobile_number"
              type="number"
              v-model="payload.mobile_number"
              class="w-100"
              :readonly="true"
              style="padding-left: 40px"
              :disabled="true"
            />
            <label for="mobile_number">رقم جوال مدير الجمعية</label>
          </span>
        </div>
        <div class="verified-icon" v-if="payload.is_mobile_verified || (payload.the_charity_manager_is_the_chairman &&
              payload.chairman_mobile === payload.mobile_number && payload.is_chairman_mobile_verified)">
          <img :src="`${staticUrl}/static/images/verified.png`" alt="verified-img" />
        </div>
        <div class="verification" v-else>
          <button
            class="btn btn-lg btn-primary btn-block verify-btn"
            @click.stop="toggleMobileVerificationModal(0)"
            type="button"
          >
            تحقق
          </button>
        </div>
      </div>

      <div class="form-label-group mb-3" style="position: relative">
        <div class="mt-4">
          <span class="p-float-label">
            <InputText
              id="email"
              type="email"
              v-model="payload.email"
              class="w-100"
              :readonly="true"
              style="padding-left: 40px"
              :disabled="true"
            />
            <label for="email">بريد مدير الجمعية الإلكتروني</label>
          </span>
        </div>
        <div class="verified-icon" v-if="payload.is_email_verified">
          <img :src="`${staticUrl}/static/images/verified.png`" alt="verified-img" />
        </div>
        <div class="verification" v-else>
          <button
            class="btn btn-lg btn-primary btn-block verify-btn"
            @click.stop="toggleEmailVerificationModal(0)"
            type="button"
          >
            تحقق
          </button>
        </div>
      </div>
    </form>

    <div class="border-container"></div>

    <form class="form-step1 w-100">
      <div class="form-label-group mb-3" style="position: relative">
        <div class="mt-4">
          <span class="p-float-label">
            <InputText
              id="chairman_mobile"
              type="number"
              v-model="payload.chairman_mobile"
              class="w-100"
              :readonly="true"
              style="padding-left: 40px"
              :disabled="true"
            />
            <label for="chairman_mobile"
              >التحقق من رقم جوال رئيس مجلس الإدارة
            </label>
          </span>
        </div>
        <div
          class="verified-icon"
          v-if="
            payload.is_chairman_mobile_verified ||
            (payload.the_charity_manager_is_the_chairman &&
              payload.chairman_mobile === payload.mobile_number && payload.is_mobile_verified)
          "
        >
          <img :src="`${staticUrl}/static/images/verified.png`" alt="verified-img" />
        </div>
        <div class="verification" v-else>
          <button
            class="btn btn-lg btn-primary btn-block verify-btn"
            :disabled="loading"
            @click.stop="toggleMobileVerificationModal(1)"
            type="button"
          >
            تحقق
          </button>
        </div>
      </div>

      <div class="form-label-group mb-3" style="position: relative">
        <div class="mt-4">
          <span class="p-float-label">
            <InputText
              id="charity_email"
              type="email"
              v-model="payload.charity_email"
              class="w-100"
              :readonly="true"
              style="padding-left: 40px"
              :disabled="true"
            />
            <label for="charity_email">التحقق من بريد الجمعية الإلكرتوني</label>
          </span>
        </div>
        <div class="verified-icon" v-if="payload.is_charity_email_verified">
          <img :src="`${staticUrl}/static/images/verified.png`" alt="verified_img" />
        </div>
        <div class="verification" v-else>
          <button
            class="btn btn-lg btn-primary btn-block verify-btn"
            :disabled="loading"
            @click.stop="toggleEmailVerificationModal(1)"
            type="button"
          >
            تحقق
          </button>
        </div>
      </div>
    </form>

    <div class="row">
      <div class="col-lg-6">
        <button
          id="close-btn"
          class="btn btn-lg btn-secondary btn-block close-btn"
          type="button"
          @click.stop="$emit('closeVerificationStep')"
        >
          إغلاق
        </button>
      </div>
      <div class="col-lg-6">
        <button
          id="tracking-btn"
          class="btn btn-lg btn-primary btn-block tracking-btn"
          type="button"
          :disabled="!payload.is_mobile_verified || !payload.is_email_verified || loading"
          @click.stop="onVerificationSubmitted"
        >
          <span v-if="loading" class="spinner-border spinner-border-sm mx-1"></span>
          متابعة
        </button>
      </div>
    </div>

    <VerificationModal
      :display="isMobileOTPModalOpened[0]"
      @onClose="onMobileModalClosed($event,0)"
      headerMessage="يرجى إدخال رمز التحقق المرسل على رقم الجوال"
      :requestType="otpType.VERIFY_MOBILE"
      :mobileNumber="payload.mobile_number"
      :email="null"
      :model_id="payload.id"
    />

    <VerificationModal
      :display="isEmailOTPModalOpened[0]"
      @onClose="onEmailModalClosed($event,0)"
      headerMessage="يرجى إدخال رمز التحقق المرسل على البريد الإلكتروني"
      :requestType="otpType.VERIFY_EMAIL"
      :mobileNumber="null"
      :email="payload.email"
      :model_id="payload.id"
    />
    <VerificationModal
      :display="isMobileOTPModalOpened[1]"
      @onClose="onMobileModalClosed($event,1)"
      headerMessage="يرجى إدخال رمز التحقق المرسل على رقم الجوال"
      :requestType="otpType.CHAIRMAN_MOBILE"
      :mobileNumber="payload.chairman_mobile"
      :email="null"
      :model_id="payload.id"
    />

    <VerificationModal
      :display="isEmailOTPModalOpened[1]"
      @onClose="onEmailModalClosed($event,1)"
      headerMessage="يرجى إدخال رمز التحقق المرسل على البريد الإلكتروني"
      :requestType="otpType.CHARITY_EMAIL"
      :mobileNumber="null"
      :email="payload.charity_email"
      :model_id="payload.id"
    />
  </div>
</template>

<script>
import Checkbox from "primevue/checkbox";
import VerificationModal from "./verification-modal.vue";
import VerifyInfoModal from "./verify-info-modal.vue";
import { OTP_TYPE } from "@/services/otp.service";

export default {
  name: "Step5Verification",
  inject: ['staticUrl'],
  props: ["payload", "verified_info", "next", "setPayload"],
  emits: ["closeVerificationStep", "createCharity"],
  components: {
    Checkbox,
    VerificationModal,
    VerifyInfoModal,
  },
  data() {
    return {
      otpType: OTP_TYPE,
      isMobileOTPModalOpened: [false, false],
      isEmailOTPModalOpened: [false, false],
      open_verify_info_modal: false,
      requestType: null,
      mobileNumber: null,
      email: null,
      loading: false,
    };
  },
  methods: {
    onMobileModalClosed(data,num) {
      if (num==0 && data.isOtpVerified) {
        this.$emit("setPayload", "is_mobile_verified", true);
        if(this.payload.the_charity_manager_is_the_chairman && this.payload.chairman_mobile === this.payload.mobile_number && this.payload.is_mobile_verified) {
          this.$emit("setPayload", "is_chairman_mobile_verified", true);
        }
      }
      else if (num == 1 && data.isOtpVerified) {
        this.$emit("setPayload", "is_chairman_mobile_verified", true);
        if(this.payload.the_charity_manager_is_the_chairman && this.payload.chairman_mobile === this.payload.mobile_number && this.payload.is_chairman_mobile_verified) {
          this.$emit("setPayload", "is_mobile_verified", true);
        }
      }
      this.toggleMobileVerificationModal(num);
    },
    toggleMobileVerificationModal(num) {
        this.isMobileOTPModalOpened[num] = !this.isMobileOTPModalOpened[num];
    },
    onEmailModalClosed(data, num) {
      if (num==0 && data.isOtpVerified) {
        this.$emit("setPayload", "is_email_verified", true);
      }
      else if (num == 1 && data.isOtpVerified) {
        this.$emit("setPayload", "is_charity_email_verified", true);
      }
      this.toggleEmailVerificationModal(num);
    },
    toggleEmailVerificationModal(num) {
      this.isEmailOTPModalOpened[num] = !this.isEmailOTPModalOpened[num];
    },
    onVerificationSubmitted() {
     this.$emit("createCharity");
    }
  },
};
</script>

<style lang="scss" scoped>
.welcome-container {
  .title {
    font-size: 24px;
    font-weight: bold;
  }
  .sub-title {
    font-size: 18px;
  }
}
.accept-checkbox {
  label {
    font-size: 14px;
    color: #3f3f3f;
    margin-right: 5px;
  }
}
.border-container {
  border-top: 1px solid #dadada;
  padding-top: 2rem;
  margin-top: 2rem;
}

.tracking-btn {
  display: block;
  margin: 0 auto;
  width: 80%;
  border-radius: 25px;
  padding: 10px;
  background: #12b2aa;
  color: #ffffff;
  border: none;
  font-size: 18px;
}

.tracking-btn:hover {
  background-color: #0e8e88 !important;
  border: none;
}

.close-btn {
  display: block;
  margin: 0 auto;
  width: 80%;
  border-radius: 25px;
  padding: 10px;
  background: #d9d9d9;
  color: #3f3f3f;
  border: none;
  font-size: 18px;
}
</style>
