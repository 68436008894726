<template>
    <div class="d-flex justify-content-between align-items-center">
      <div class="mt-4 mb-2">
        <img
            :src="file"
            class="file-image object-fit-contain"
            alt="Photo id"
            height="53"
            width="71"
            v-if="file && !file?.endsWith('.pdf')"
          />
        <a :href="href" target="_blank" rel="noopener noreferrer" :class="[ image ? 'px-3' : '', 'link-color' ]">
          <span :data-cy="datacy">{{ fileName }}</span>
        </a>
        <BaseTooltip placement="left" v-if="tooltipTitle || tooltipContent">
          <template #trigger>
            <i class="pi pi-info-circle icon-primary me-2 text-primary h5"/>
          </template>
          <template #content>
            <span>{{ tooltipTitle }}</span>
            <ul class="mt-2 mb-0">
              <li v-for="(item, index) in tooltipContent" :key="index">&#x2022; {{ item }}</li>
            </ul>
          </template>
        </BaseTooltip>
      </div>
    </div>
  </template>
  
  <script>
  import BaseTooltip from './BaseTooltip.vue';
  
  export default {
    components: {
      BaseTooltip
    },
    props: {
      href: {
        type: String,
        required: true
      },
      file:{
        type: Object,
        required: false
      },
      datacy: {
        type: String,
        required: false
      },
      fileName: {
        type: String,
        required: true
      },
      tooltipTitle: {
        type: String,
        required: false
      },
      tooltipContent: {
        type: Array,
        required: false
      }
    }
  }
  </script>
  
