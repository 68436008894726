import uq from '@umalqura/core'

export const formatDate = (date) => {
  // Check if the date is already in the format YYYY-MM-DD
  if (typeof date === 'string' && /^\d{4}-\d{2}-\d{2}$/.test(date)) {
    return date;
  }
  try {
    let year = date.toLocaleString("default", { year: "numeric" });
    let month = date.toLocaleString("default", { month: "2-digit" });
    let day = date.toLocaleString("default", { day: "2-digit" });
    return `${year}-${month}-${day}`;
  } catch (error) {
    return date;
  }
};
const checkDate = (date) => {
  if (!date || typeof date !== 'string') {
    return '';
  }
  return date.split(/[-/]/).map(Number);
}
export function convertToHijri(gregorianDate) {
  const [year, month, day] = checkDate(gregorianDate);
  if (!year) return '';
  const { hy: hijriYear, hm: hijriMonth, hd: hijriDay } = uq(new Date(year, month - 1, day));
  return `${hijriYear}-${String(hijriMonth).padStart(2, '0')}-${String(hijriDay).padStart(2, '0')}`;
}
export function convertToGregorian(hijriDate) {
  const [year, month, day] = checkDate(hijriDate);
  if (!year) return '';
  try {
    const { date: gregorianDate } = uq(year, month, day);
    return `${gregorianDate.getFullYear()}-${String(gregorianDate.getMonth() + 1).padStart(2, '0')}-${String(gregorianDate.getDate()).padStart(2, '0')}`;
  } catch {
    // Invalid Hijri Date
    return '';
  }
}
// It checks if date is valid or not i.e YYYY-MM-DD (in Digits) and if it's compliant to real date in the Gregorian case
export function isValidDate(dateString, type) {
  const regex = /^\d{4}-\d{2}-\d{2}$/;
  if (!regex.test(dateString)) return false;
  
  const date = new Date(dateString);
  if (isNaN(date.getTime())) return false;
  
  return type === "hijri" || date.toISOString().slice(0, 10) === dateString;
}