<template>
  <section class="contact-area bg-white pt-5" v-if="showFooter">
    <div class="container">
      <div class="row mx-0" data-aos="fade-up" data-aos-delay="200">
        <div class="col-12">
          <h4 class="footer-title text-center fs-1 pb-5 mb-0">شركاؤنا</h4>
        </div>
        <div class="col-12">
            <div class="footer-link-logo d-flex flex-wrap align-items-center justify-content-evenly" data-cy="our-partners-logo">
              <VLazyImage class="footer-partner-logo object-fit-contain w-100" data-toggle="tooltip"
                  :src="`${staticUrl}/static/images/gomaih.png`" 
                  title="الجميح" alt="الجميح" 
                  width="194"
                  height="180"
              />
              <VLazyImage class="footer-partner-logo object-fit-contain w-100" data-toggle="tooltip"
                  :src="`${staticUrl}/static/images/council_of_health.png`"
                  title="مجلس الضمان الصحي"
                  alt="مجلس الضمان الصحي" 
                  width="194"
                  height="180"
              />
            </div>
        </div>
      </div>
    </div>
  </section>

  <footer class="footer-area">
    <div class="footer-copyright position-relative pt-5">
      <div class="container pt-4">
          <a class="to-top position-absolute d-block text-decoration-none text-white" @click="scrollToTop" role="button">
            <VLazyImage class="w-100 h-100" :src="`${staticUrl}/static/icons/to-top.svg`" width="75" height="82" alt="top-button"/>
          </a>
        <div class="row align-items-center pt-3">
          <div class="d-flex align-items-center justify-content-center text-white mb-4">
            <router-link class="text-decoration-none text-white" to="/partnership-request">
              <div class="px-2 pointer text-center">طلب شراكة</div>
            </router-link>
            <div class="v-separator"></div>
            <div class="px-2 pointer text-center">
              <router-link class="text-decoration-none text-white" to="/privacy-policy">
                سياسة الاستخدام و الخصوصية
              </router-link>
            </div>
            <div class="v-separator"></div>
            <div class="px-2 pointer text-center">
              <router-link class="text-decoration-none text-white" to="/faq">
                الأسئلة المتكررة
              </router-link>
            </div>
            <div class="v-separator" v-if="!this.isAdmin"></div>
            <div  v-if="!this.isAdmin" class="px-2 pointer text-center">
              <router-link class="text-decoration-none text-white" to="/support-tickets/create">
                الدعم الفني              
              </router-link>
            </div>
          </div>
          <div class="copyright text-center mt-3">
            <p class="d-inline-block text-white">جميع الحقوق محفوظة لمنصة شفاء للعلاج الخيري {{toIndianDigits(year)}} | احدى مبادرات صندوق الوقف الصحي </p>
            <VLazyImage
              :src="`${staticUrl}/static/icons/footer-logo.svg`"
              class="footer-logo me-3"
              alt="Footer Logo" 
              width="160"
              height="28"
            />
          </div>
        </div>
      </div>
    </div>
  </footer>

</template>

<script>
import { useStore } from "vuex";
import { USER_ROLES_AR } from '@/services/enum.service';
import VLazyImage from "v-lazy-image";
import JwtUtilService from "@/services/jwt.util.service";

export default {
  name: "FooterSection",
  inject: ['staticUrl'],
  components: { VLazyImage },
  data() {
    return {
      year: new Date().getFullYear(),
      charityUsers: [USER_ROLES_AR.CHARITY_MANAGER, USER_ROLES_AR.CHARITY_REPRESENTATIVE],
      store: useStore(),
    };
  },
  methods: {
    scrollToTop() {
      if (window) {

        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth"
        });
      }
    },
    toIndianDigits(num) {
      const id= ['۰','۱','۲','۳','٤','۵','۶','۷','۸','۹'];
      return num.toString().replace(/\d/g, (w) => {
        return id[+w]
      });
    },
  },
  computed: {
    currentRoute() {
      return this.$route.path.startsWith('/admin-panel');
    },
    // This function is used to hide footer contact area in admin panel for charity users
    showFooter() {
      if(this.currentRoute) {
        return !this.charityUsers.includes(this.store.state.adminPanelModule.userRole);
      }
      return true
    },
    isAdmin() {
      return JwtUtilService.getClientID()?.includes("admin");
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
.footer-area {
  .footer-copyright {
    padding-bottom: 15px;
    background: $color-footer-bcg;

    .copyright {
      .footer-logo {
        width: 160px;
      }
    }
  }

  .to-top {
    width: 75px;
    height: 82px;
    z-index: 10;
    left: 50%;
    top: 0;
    transform: translate(-50%, -50%);
  }
}
.contact-area {
  padding-bottom: 100px;
  .footer-link-logo {
    img {
      max-width: 194px;
      height: 180px;
      @media screen and (max-width:600px) {
        max-width: 115px;
      }
    }
  }
}
.v-separator {
  width: 1px;
  height: 20px;
  margin: 4px 10px 0;
  background-color: #3D3D3D;
}

.pointer {
  cursor: pointer;
}
</style>
