<template>
    <div class="p-inputgroup flex-row-reverse">
        <Button 
            v-if="!disabled"
            icon="pi pi-calendar"
            class="px-4"
            @click="state.show = true"
            :disabled="disabled"
        />
        <span class="p-float-label">
            <InputText 
                :id="calendarId" 
                v-model="date"
                :class="[
                    { 'p-filled': modelValue?.length, 'p-invalid': (!date) && ($attrs.class?.includes('p-invalid') || state.isInvalid), 'updated-field': $attrs.class?.includes('updated-field') },
                    'w-100'
                ]"
                @blur="handleBlur"
                :maxlength="10"
                :disabled="disabled"
            />
            <label :for="calendarId" class="end-0 pe-3">
                <small>{{ placeholder }}</small>
            </label>
        </span>
    </div>
    <VueDatetimeJs 
        :color="'#12B2aa'"
        :locale="type === 'hijri' ? 'ar-sa' : 'en'"
        :calendar="type"
        :element="calendarId"
        :editable="true"
        :show="state.show"
        :max="computedMaxMinDates.maxDate"
        :min="computedMaxMinDates.minDate || (props.type === 'hijri' && state.baseHijriDate)"
        :value="date"
        @input="handleInput"
        @close="state.show = false"
        :autoSubmit="true"
    />
</template>

<script setup>
import { reactive, ref, watch, computed, onMounted } from 'vue';
import VueDatetimeJs from 'vue3-datetime-js';
import { convertToHijri, convertToGregorian, isValidDate, formatDate }  from '@/helpers/useDateConverter';

const props = defineProps({
    calendarId: {
        type: String,
        required: true
    },
    type: {
        type: String,
        default: 'gregorian'
    },
    placeholder: {
        type: String,
        required:true
    },
    modelValue: {
        type: [String, Number, Date],
        default: ''
    },
    maxDate: {
        type: [String, Date],
        default: ''
    },
    minDate: {
        type: [String, Date],
        default: ''
    },
    disabled: {
        type: Boolean,
        default: false
    }
})
const state = reactive({
    isInvalid: false,
    show: false,
    baseHijriDate: '1356/01/01'
});
const date = ref(props.modelValue && formatDate(props.modelValue) || '');
// *********** COMPUTED ***********
const computedMaxMinDates = computed(() => {
    /**
     * @param date - Date Object / Empty String.
     * @property {string} maxDate - "YYYY-MM-DD" / Empty string.
     * @property {string} minDate - "YYYY-MM-DD" / Empty string.
     * @returns {object} - { maxDate, minDate }
     */
    const formatComputedDate = (date) => {
        if (!date) return '';
        return props.type === 'gregorian' ? formatDate(date) 
               : props.type === 'hijri' ? convertToHijri(formatDate(date)) 
               : '';
    };
    return {
        maxDate: formatComputedDate(props.maxDate),
        minDate: formatComputedDate(props.minDate)
    };
});
const emit = defineEmits(['update:modelValue', 'dateChange'])
// *********** FUNCTIONS ***********
/**
 * @param {string} value - value to be adjusted.
 * @returns {string} - "YYYY-MM-DD" format, or the original value if the format is invalid.
 */
const adjustDateFormat = (value) => {
    const regex = /^\d{4}-\d{1,2}-\d{1,2}$/;
    if (!regex.test(value)) return value;
    const [year, month, day] = value.split('-').map(part => part.padStart(2, '0'));
    return `${year}-${month}-${day}`;
};
const convertDate = (value) => {
    /**
     * if not empty string, value gets converted into Hijri if the type is Gregorian, and vice versa.
     * Emits the final date value to the parent component where both Gregorian and Hijri dates are updated.
     * 
     * @param value - Date in "YYYY-MM-DD" format or empty string.
     */
    date.value = (props.type === 'hijri' && !convertToGregorian(value)) ? '' : value;
    const finalDate = props.type === 'gregorian' ? convertToHijri(value) 
                    : props.type === 'hijri' ? convertToGregorian(value) 
                    : value;
    emit('update:modelValue', date.value);
    emit('dateChange', finalDate || '');
};
const handleInput = (event) => {
    // event parameter (on calendar pick) is in "YYYY/MM/DD" format, gets converted into "YYYY-MM-DD" format.
    const validDate = event.replace(/\//g, '-');
    convertDate(validDate);
};
const handleBlur = () => {
    // On mouse click outside the input field, the date is Adjusted, Validated, Converted and Emitted.
    const adjustedDate = adjustDateFormat(date.value);
    if (adjustedDate === formatDate(props.modelValue)) return;

    const { maxDate, minDate } = computedMaxMinDates.value;
    const validDate = isValidDate(adjustedDate, props.type)
        ? new Date(adjustedDate) > new Date(maxDate) ? maxDate 
        : new Date(adjustedDate) < new Date(minDate) ? minDate 
        : adjustedDate
        : '';
    convertDate(validDate);
};
// *********** WATCHERS ***********
/**
 * data.value gets updated based on user input or an API call (on Blur).
 * @param {any} newValue
 */
watch(() => props.modelValue, (newValue) => {
        date.value = newValue && formatDate(newValue) || '';
        state.isInvalid = !date.value;
    }
);
// *********** LIFECYCLE HOOKS ***********
onMounted(() => {
    // if gets gregorian date initially, updates hijri calendar accordingly.
    if (props.type === 'gregorian' && date.value !== '') {
        convertDate(date.value);
    }
});
</script>
<style scoped>
.updated-field {
  border: 3px solid #ffccaaef;
}
</style>